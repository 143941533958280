import React from "react";
import { BackgroundGradientAnimation } from "./BackgroundGradientAnimation";

// @ts-ignore
const IconComponent = ({ className }) => (
  <div className="relative flex items-center p-1">
    <div className="relative flex items-center">
      <div className="relative flex items-center group">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          className={className}
          fill="currentColor"
        >
          <path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 32 14.33 32 32c0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 24-24h16v-64H224c-13.25 0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z" />
        </svg>
        <div className="absolute w-80 p-1 left-1/2 top-full mt-2 transform -translate-x-1/2 text-gray-100 text-xs opacity-0 transition-opacity duration-300 shadow-lg z-10 group-hover:opacity-100 pointer-events-none">
          <BackgroundGradientAnimation className="relative rounded-3xl">
            <div className="relative bg-[#000000] p-4 rounded-3xl">
              <p className="mb-2 text-left text-xs">
                Your Roobet wagers will be applied to the leaderboard using
                these weighted guidelines to ensure fair competition and prevent
                exploitation.
              </p>
                             <ul className="list-disc pl-5 space-y-1 text-left">
                <li>
                  Wagers placed on games with a Return to Player (RTP) rate of
                  97% or lower will be credited at 100% of their value towards
                  the leaderboard.
                </li>
                <li>
                  Wagers placed on games with an RTP rate exceeding 97% will be
                  credited at 50% of their value towards the leaderboard.
                </li>
                <li>
                    Wagers placed on games with an RTP of 99% or higher (house games) 
                    will be credited at 10% of the wager. Dice, live games, and sports 
                    bets count for 0%.
                </li>
              </ul>
            </div>
          </BackgroundGradientAnimation>
        </div>
      </div>
    </div>
  </div>
);

export default IconComponent;
